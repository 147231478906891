<template>
  <div class="rewardList">
    <PullRefresh
      :loading="loading"
      :refreshing="refreshing"
      :finished="finished"
      @onLoad="onLoad"
      @onRefresh="onRefresh"
      :isHigehtMax="true"
      :isNoData="isNoData"
      :error="error"
    >
      <div class="top" style="width: 100%; height: 1px"></div>
      <div class="commentItem" v-for="item in list" :key="item.id">
        <div class="userInfo"></div>
        <router-link
          tag="div"
          :to="'/userHomepage?uid=' + item.senderUid"
          class="avatarBox"
        >
          <ImgDecypt
            class="avatarImg"
            :src="item.senderPortrait"
            :key="item.portrait"
            round
          />
        </router-link>
        <div class="msgInfo" @click.stop="toPostDetails(item)">
          <div class="userName">{{ item.senderName }}</div>
          <div class="time">
            <svg-icon
              class="sexIcon"
              iconClass="maleIcon"
              v-if="item.gender == 'male'"
            />
            <svg-icon
              class="sexIcon"
              iconClass="womanIcon"
              v-if="item.gender == 'female'"
            />{{ item.createdAt | dateago }}
          </div>
          <div class="content">
            {{ item.detail.content }}
          </div>
          <span class="btn" @click.stop="reply(item)">回复</span>
        </div>
        <div class="cover" @click.stop="toPostDetails(item)">
          <ImgDecypt :src="item.videoInfo.cover" :key="item.videoInfo.id" />
        </div>
      </div>
    </PullRefresh>
    <van-overlay :show="isInputShow" @click.stop="isInputShow = false">
      <div class="wrapper" @click.stop>
        <div class="footer">
          <div class="addComment">
            <van-field
              v-model="commentParams.content"
              autosize
              rows="1"
              type="textarea"
              @blur="blur"
              :placeholder="placeholder"
              class="commentInputBox"
              :border="false"
            >
            </van-field>
            <div class="commentBtn" @click="submintComment">
              <img src="@/assets/png/sendMsg.png" />
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import {
  queryMsgList,
  queryComment,
  getSecCommentList,
  sendComment,
  thumbsUpComment,
  thumbsDownComment,
} from "@/api/mine";
import ImgDecypt from "@/components/ImgDecypt";
import PullRefresh from "@/components/PullRefresh";
import { Toast } from "vant";
export default {
  components: {
    ImgDecypt,
    PullRefresh,
  },
  data() {
    return {
      list: [],
      pageNumber: 1,
      pageSize: 10,
      loading: true,
      refreshing: false,
      finished: false,
      error: false,
      isNoData: false,
      placeholder: "留下你的精彩评论吧～",
      commentParams: {
        objID: "", // 帖子ID,
        level: 1, // 评论级别，1: 一级评论;2: 二级评论
        content: "", // 评论内容
        cid: "", // 一级评论的ID
        userID: "", // 一级评论的用户ID
        currentFirstItem: {}, // 当前一级评论对象
        currentHasNext: false, // 当前评论是否还有下一页数据
        currentLoading: false, // 当前二级评论的loading
        currentShowInputId: "", // 当前显示的输入框id
      },
      isInputShow: false,
      itemComment: {},
    };
  },
  created() {
    this.getList();
  },
  methods: {
    blur() {
      window.scrollTo(0, 0);
    },
    // 确认提交评论
    async submintComment() {
      if (!this.commentParams.content) {
        Toast("请输入回复内容");
        return;
      }
      try {
        let req = {};
        if (this.itemComment?.detail?.cid) {
          req = {
            objID: this.itemComment?.detail?.objID,
            level: 2,
            content: this.commentParams.content,
            objType: this.itemComment?.detail?.objType,
            isTop: false,
            toUserID: this.itemComment.senderUid,
            cid: this.itemComment?.detail?.cid,
            rid: this.itemComment?.detail?.id,
          };
        } else {
          req = {
            objID: this.itemComment?.detail?.objID,
            level: 2,
            content: this.commentParams.content,
            objType: this.itemComment?.detail?.objType,
            toUserID: this.itemComment.senderUid,
            isTop: false,
            cid: this.itemComment?.detail?.id,
          };
        }

        let res = await this.$Api(sendComment, req);
        this.commentParams.content = ""; // 评论输入框内容清空
        // if (state.commentParams.level == 1) {
        //     moreParams.isNoData = false;
        //     state.list.unshift(res);
        // } else if (state.commentParams.level == 2) {
        //     state.list = state.list.map((item) => {
        //         if (item.id == state.commentParams.currentFirstItem.id) {
        //             item.isShow = true;
        //             // if (!item.subComments) {
        //             //     item.subComments = [];
        //             //     state.commentParams.currentLoading = false;
        //             //     state.commentParams.currentHasNext = false;
        //             // }
        //             item.Info.unshift(res);
        //         }
        //         return item;
        //     });
        // }
        this.clearReply();
        Toast("回复成功");
      } catch (e) {
        console.log(e);
      }
    },
    clearReply() {
      this.isInputShow = false;
      this.commentParams.currentFirstItem = {};
      this.commentParams.objID = "";
      this.commentParams.cid = "";
      this.commentParams.userID = "";
      this.commentParams.level = 1;
      this.placeholder = `留下你的精彩评论吧～`;
    },
    reply(item) {
      this.itemComment = item;
      this.isInputShow = true;
    },
    toPostDetails(item) {
      if (this.$router.path === "/postDetails") return;
      this.$router.push({
        path: "/postDetails",
        query: {
          videoID: item.videoInfo.id,
          // fromPersonal: this.$router.path == '/userHomepage' ? 1 : 0,
        },
      });
    },
    // 返回
    backPage() {
      this.$router.go(-1);
    },
    // 查询列表
    async getList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        type: 0,
      };
      try {
        let res = await this.$Api(queryMsgList, req);
        console.log("评论++++++", res);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          list.forEach((i) => {
            i.isFollow = true;
          });
          if (type === "refresh") {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
            return;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    },
    // 上啦加载
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList("refresh");
    },
  },
};
</script>

<style lang="scss" scoped>
.rewardList {
  height: calc(100vh - 188px);
  color: #000;
  padding: 0 16px 0;
  box-sizing: border-box;
  z-index: -1;

  .commentItem {
    display: flex;
    // align-items: center;
    margin-top: 18px;
    padding-bottom: 16px;
    border-bottom: 1px solid #e6e6e6;

    .avatarBox {
      width: 40px;
      height: 40px;
      margin-right: 12px;
    }

    .msgInfo {
      flex: 2;

      .userName {
        font-size: 14px;
        color: #666;
        margin-bottom: 3px;
        // font-weight: 600;
      }
      .time {
        font-size: 12px;
        color: #999;
        display: flex;
        align-items: center;
        .sexIcon {
          width: 16px;
          height: 16px;
          margin-right: 2px;
        }
      }
      .content {
        // margin-left: 52px;
        //width: 290px;
        // height: 34px;
        width: 100%;
        font-size: 12px;
        margin: 6px 0 2px;
        color: #333;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* 这里是超出几行省略 */
        overflow: hidden;
      }
      .btn {
        font-size: 12px;
        color: #b09fd9;
      }
    }
    .cover {
      width: 60px;
      height: 60px;
      border-radius: 4px;
      /deep/ .van-image__img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
      }
    }
  }
  .commentItem:first-child {
    margin-top: 0;
  }
  .footer {
    display: flex;
    justify-content: center;

    .addComment {
      width: 375px;
      height: 92px;
      background: #ffffff;
      display: flex;
      position: fixed;
      bottom: 34px;
      //width: calc(100% - 28px);
      align-items: center;
      justify-content: center;
      .commentInputBox {
        width: 292px;
        height: 32px;
        background: #eeeeee;
        border-radius: 16px;
        margin: 0 10px 0 16px;
      }
      /deep/ .van-cell__value--alone {
        line-height: 13px;
      }
      /deep/ .van-field__control::placeholder {
        color: rgb(153, 153, 153);
      }

      .commentBtn {
        display: flex;
        align-items: center;
        img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}
</style>
