<template>
  <div class="thumbsUp">
    <PullRefresh
      :loading="loading"
      :refreshing="refreshing"
      :finished="finished"
      @onLoad="onLoad"
      @onRefresh="onRefresh"
      :isHigehtMax="true"
      :isNoData="isNoData"
      :error="error"
    >
      <div class="top" style="width: 100%; height: 1px"></div>
      <div class="commentItem" v-for="item in list" :key="item.id">
        <div class="userInfo"></div>
        <router-link
          tag="div"
          :to="'/userHomepage?uid=' + item.senderUid"
          class="avatarBox"
        >
          <ImgDecypt
            class="avatarImg"
            :src="item.senderPortrait"
            :key="item.portrait"
            round
          />
        </router-link>
        <div class="msgInfo" @click.stop="toPostDetails(item)">
          <div class="userName">{{ item.senderName }}</div>
          <div class="time">
            <svg-icon
              class="sexIcon"
              iconClass="maleIcon"
              v-if="item.gender == 'male'"
            />
            <svg-icon
              class="sexIcon"
              iconClass="womanIcon"
              v-if="item.gender == 'female'"
            />{{ item.createdAt | dateago }}
          </div>
          <div class="content">
            {{ item.desc }}
          </div>
        </div>
        <div class="cover" @click.stop="toPostDetails(item)">
          <ImgDecypt :src="item.videoInfo.cover" :key="item.videoInfo.id" />
        </div>
      </div>
      <!-- <div
        class="listItem"
        v-for="item in list"
        :key="item.id"
        @click.stop="toPostDetails(item)"
      >
        <div class="topData">
          <div class="type">#动态点赞</div>
          <div class="date">{{ item.createdAt | dateago }}</div>
        </div>
        <div class="content">{{ item.desc }}</div>
        <div class="count">{{ list.length }}个人为你点赞</div>
      </div> -->
    </PullRefresh>
  </div>
</template>

<script>
import { queryMsgList } from "@/api/mine";
import PullRefresh from "@/components/PullRefresh";
import ImgDecypt from "@/components/ImgDecypt";
export default {
  components: {
    ImgDecypt,
    PullRefresh,
  },
  data() {
    return {
      list: [],
      pageNumber: 1,
      pageSize: 10,
      loading: true,
      refreshing: false,
      finished: false,
      error: false,
      isNoData: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    toPostDetails(item) {
      if (this.$router.path === "/postDetails") return;
      this.$router.push({
        path: "/postDetails",
        query: {
          videoID: item.videoInfo.id,
          // fromPersonal: this.$router.path == '/userHomepage' ? 1 : 0,
        },
      });
    },
    // 返回
    backPage() {
      this.$router.go(-1);
    },
    // 查询列表
    async getList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        type: 1,
        // uid: this.$route.query.uid ? this.$route.query.uid : undefined,
        // uid: 224411
      };
      try {
        let res = await this.$Api(queryMsgList, req);
        console.log("点赞++++++", res);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          list.forEach((i) => {
            i.isFollow = true;
          });
          if (type === "refresh") {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
            return;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    },
    // 上啦加载
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList("refresh");
    },
  },
};
</script>

<style lang="scss" scoped>
.thumbsUp {
  height: calc(100vh - 108px);
  color: #000;
  padding: 12px 16px 0;
  box-sizing: border-box;

  .listItem {
    margin-top: 18px;
    padding-bottom: 18px;
    box-sizing: border-box;
    border-bottom: 1px solid #e6e6e6;
    .topData {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .type {
        color: #333;
        font-size: 14px;
        font-weight: 500;
      }
      .date {
        color: #666;
        font-size: 12px;
      }
    }
    .content {
      margin-top: 12px;
      width: 100%;
      font-size: 12px;
      font-weight: 500;
      color: #666;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .count {
      margin-top: 12px;
      font-size: 12px;
      color: #333;
      font-weight: 500;
    }
  }
  .listItem:first-child {
    margin-top: 0;
  }
  .commentItem {
    display: flex;
    // align-items: center;
    margin-top: 18px;
    padding-bottom: 16px;
    border-bottom: 1px solid #e6e6e6;

    .avatarBox {
      width: 40px;
      height: 40px;
      margin-right: 12px;
    }

    .msgInfo {
      flex: 2;

      .userName {
        font-size: 14px;
        color: #666;
        margin-bottom: 3px;
        // font-weight: 600;
      }
      .time {
        font-size: 12px;
        color: #999;
        display: flex;
        align-items: center;
        .sexIcon {
          width: 16px;
          height: 16px;
          margin-right: 2px;
        }
      }
      .content {
        // margin-left: 52px;
        //width: 290px;
        // height: 34px;
        width: 100%;
        font-size: 12px;
        margin: 6px 0 2px;
        color: #333;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* 这里是超出几行省略 */
        overflow: hidden;
      }
      .btn {
        font-size: 12px;
        color: #b09fd9;
      }
    }
    .cover {
      width: 60px;
      height: 60px;
      border-radius: 4px;
      /deep/ .van-image__img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
      }
    }
  }
  .commentItem:first-child {
    margin-top: 0;
  }
}
</style>
