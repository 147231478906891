<template>
  <div class="myMsg">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <source />
      <div class="navTitle">我的消息</div>
      <div class="rightBtn"></div>
    </div>
    <van-tabs
      v-model="active"
      class="msgTab"
      @change="change"
      background="rgba(0,0,0,0)"
      title-active-color="#333"
      title-inactive-color="#999"
    >
      <van-tab>
        <div slot="title" class="tabTitle">
          评论
          <div v-if="this.msgHasNew.comment" class="redDot"></div>
        </div>
        <div class="tabContent">
          <CommentList />
        </div>
      </van-tab>
      <van-tab>
        <div slot="title" class="tabTitle">
          点赞
          <div v-if="this.msgHasNew.thumbs" class="redDot"></div>
        </div>
        <ThumbsUp />
      </van-tab>
      <van-tab>
        <div slot="title" class="tabTitle">
          打赏
          <div v-if="this.msgHasNew.reward" class="redDot"></div>
        </div>
        <rewardList />
      </van-tab>
      <van-tab>
        <div slot="title" class="tabTitle">
          官方
          <div v-if="this.msgHasNew.official" class="redDot"></div>
        </div>
        <OfficialMsgList />
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { querMyMsg } from "@/api/mine";
import CommentList from "./commentList";
import ThumbsUp from "./thumbsUp";
import RewardList from "./rewardList";
import OfficialMsgList from "./officialMsgList";
import { mapGetters } from "vuex";
export default {
  components: {
    CommentList,
    ThumbsUp,
    RewardList,
    OfficialMsgList,
  },
  computed: {
    ...mapGetters({
      userInfo: "userInfo",
    }),
  },
  data() {
    return {
      list: [],
      pageNumber: 1,
      pageSize: 10,
      loading: true,
      refreshing: false,
      finished: false,
      error: false,
      isNoData: false,
      active: Number(sessionStorage.getItem("myMsgactive")) || 0,
      msgHasNew: {},
    };
  },
  watch: {
    active(newVal) {
      sessionStorage.setItem("myMsgactive", newVal);
    },
  },
  created() {
    this.queryMsgHasNew();
    this.getList();
  },
  methods: {
    async queryMsgHasNew() {
      await this.$store.dispatch("user/updateMsgHasNew");
      this.msgHasNew = this.$store.getters.msgHasNew;
    },
    change() {
      console.log(this.msgHasNew);
    },
    // 返回
    backPage() {
      this.$router.go(-1);
    },
    // 查询列表
    async getList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        uid: this.$route.query.uid ? this.$route.query.uid : undefined,
        // uid: 224411
      };
      try {
        let res = await this.$Api(querMyMsg, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          list.forEach((i) => {
            i.isFollow = true;
          });
          if (type === "refresh") {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
            return;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    },
    // 上啦加载
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList("refresh");
    },
  },
};
</script>

<style lang="scss" scoped>
.myMsg {
  height: 100%;
  color: #000;
  background: #f5f5f5;
  .navBar {
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;

    .navArrow {
      width: 17px;
      height: 17px;
    }

    .navTitle {
      flex: 2;
      text-align: center;
      font-size: 18px;
      color: #000;
    }

    .rightBtn {
      width: 17px;
      height: 17px;
    }
  }
  .msgTab {
    /deep/ .van-tabs__wrap {
      width: 342px;
      height: 33px;
      margin: 20px auto 0;
      // padding: 0 50px;
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.5);
      background: #f0f0f3;
    }
    /deep/ .van-tabs__line {
      width: 18px;
      height: 2px;
      bottom: 22px;
      background: #94d6da;
      display: none;
    }
    /deep/ .van-tab__text {
      font-size: 14px;
    }
    .tabTitle {
      position: relative;
      width: 77px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      .redDot {
        position: absolute;
        top: 0;
        right: 0px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #fc4162;
      }
    }
    /deep/ .van-tab--active {
      .tabTitle {
        width: 77px;
        height: 26px;
        border-radius: 8px;
        background: url("../../../assets/png/tabBg.png");
        background-size: 100% 100%;
        // border: 2px solid rgba(0, 0, 0, 0.05);
        // background: #f0f0f3;
      }
    }
  }
}
</style>
